<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step d-flex align-items-center mx-auto justify-content-center">
        <Spinner v-if="!mounted" />
        <div v-else>
          <small class="text-danger" v-if="getErrors.profile">
            {{ getErrors.profile }}
          </small>

          <div class="alert alert-danger" v-if="getErrors.get_tenant">
            {{ getErrors.get_tenant }}
          </div>
          <div class="alert alert-danger" v-if="getErrors.tenant">
            {{ getErrors.tenant }}
          </div>

          <div class="w-100" v-if="step == 1">
            <h1>
              {{ $t("What Is your relationship to the guarantor?") }}
            </h1>

            <p class="fw-medium text-gray">
              {{
                $t(
                  "We need to know your relationship to the guarantor in order to double check your application."
                )
              }}
            </p>
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio
                class="mb-2"
                v-model="form.relation"
                :aria-describedby="ariaDescribedby"
                name="some-radios"
                :value="relation.key"
                v-for="relation in relations"
                :key="relation.key"
              >
                {{ $t(relation.value) }}
              </b-form-radio>
            </b-form-group>

            <TextInput
              v-if="form.relation == 'other'"
              v-model="form.relation_text"
              name="relation"
              placeholder="Relation with guarantor"
              label="Relation with guarantor"
            />

            <div class="w-100 mt-4">
              <button
                class="btn btn-primary btn-block mb-3"
                :disabled="
                  getLoadings.tenant ||
                  !form.relation ||
                  (form.relation == 'other' && !form.relation_text)
                "
                @click="submit"
              >
                {{ $t("Next step") }}
                <b-spinner
                  v-if="getLoadings.tenant"
                  small
                  label="Spinning"
                  variant="white"
                >
                </b-spinner>
              </button>
              <button class="btn btn-outline-white btn-block" @click="previous">
                {{ $t("Previous step") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <RightSection>
        <div class="step__rightSection mx-auto">
          <img src="../../../assets/images/guarantor/step2.svg" alt="" />
        </div>
      </RightSection>
    </template>
  </AuthLayoutContent>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import RightSection from "@/components/common/RightSection.vue";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import Spinner from "../../../components/ui/Spinner.vue";
import TextInput from "@/components/ui/form/controls/TextInput.vue";

export default {
  name: "GuarantorStep2",
  data() {
    return {
      error: "",
      form: {
        is_guarantor: true,
        tenant_id: null,
        relation: "",
        relation_text: "",
      },
      relations: [
        {
          key: "parent",
          value: "Parent",
        },
        {
          key: "family_member",
          value: "Family Member",
        },
        {
          key: "other",
          value: "Other",
        },
      ],
      mounted: false,
    };
  },
  components: {
    RightSection,
    AuthLayoutContent,
    Spinner,
    TextInput,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("tenant", ["tenant"]),
    ...mapGetters("auth", ["guarantor"]),
    step() {
      return this.$route.params.step || 1;
    },
  },
  async mounted() {
    console.log("mounted");
    this.mounted = true;
  },
  methods: {
    ...mapActions("tenant", ["updateTenant", "getTenant"]),
    ...mapMutations("tenant", ["setTenant"]),
    previous() {
      this.$router.push(`/user/guarantor/1`);
    },
    async submit() {
      if (
        this.form.relation == undefined ||
        (this.form.relation == "other" && !this.form.relation_text)
      ) {
        return;
      }

      let data = { ...this.form };
      if (data.relation == "other") {
        data.relation = data.relation_text;
      }

      let tenant = await this.updateTenant(data);
      this.$router.push(`/user/income/1/${tenant.id}`);
    },
  },
  watch: {
    $route() {
      console.log("route watch");
      // this.step = this.$route.params.step;
    },
    guarantor() {
      this.form.tenant_id = this.guarantor.id;
      this.form.relation = this.guarantor?.relation;
    },
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}
</style>
